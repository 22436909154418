.newsletter-section {
  margin: 20px auto 0px auto;
  display: flex;
  padding: 10px;
  border-top: solid 2px #f9f9f9;
  background-color: #ffffff;
}

.newsletter-container {
  max-width: 650px;
  margin: 0 auto;
  padding: 5px 10px 5px 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  align-items: center;
  display: flex;
  border: solid #dcdcdc 1px;
}

.newsletter-container p {
  font-size: 16px;
  color: #000000;
  /* margin-bottom: 10px; */
  margin-right: 5px;
}

.newsletter-form {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .newsletter-form {
    display: grid;
  }
  .newsletter-form p {
    margin-top: 0px;
  }
}

.newsletter-input {
  flex: 1;
  padding: 8px;
  border: 2px solid #e5e7eb;
  border-radius: 5px;
  transition: border-color 0.3s ease;
  color: #333;
  font-size: 14px;
  margin-right: 10px;
}

.newsletter-input:focus {
  outline: none;
  border-color: #2f8332;
}

.newsletter-button {
  padding: 10px 20px;
  background-color: #999b9a;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.newsletter-button:hover {
  background-color: #238029;
}

/* Responsive styles */
@media (max-width: 768px) {
  .newsletter-container {
    max-width: 100%;
    padding: 15px;
  }

  .newsletter-form {
    flex-direction: column;
  }

  .newsletter-input {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
