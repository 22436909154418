/* ----------------hier weiter schauen ----------------- */
.home-page body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osxfont-smoothing: grayscale;
}

.home-page code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.home-page header {
  max-width: 100%;
  background-color: #000000;
}

.home-page header .hero-section {
  text-align: left;
  margin: auto;
  padding: 10px;
  max-width: 1200px;
  padding-top: 100px;
  padding-bottom: 100px;
}

/* Adjust font size for mobile */
@media (max-width: 768px) {
  .home-page header .hero-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.home-page header h1 {
  font-size: 60px;
  color: #ffffff;
  margin-bottom: 10px;
  text-align: center;
}

.home-page header h1 strong {
  color: #16db93;
}
.home-page header h1 span {
  font-size: 0.4em;
  font-weight: normal;
  color: white;
}

@media (max-width: 768px) {
  .home-page header h1 {
    font-size: 2.5em;
  }
}

.home-page header p {
  font-size: 22px;
  color: #ffffff;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  color: rgb(188, 188, 188);
}

/* Adjust paragraph text size for mobile */
@media (max-width: 768px) {
  .home-page header p {
    font-size: 16px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.actionButton-container {
  display: flex;
  justify-content: center; /* Centers the buttons horizontally */
  margin-top: 20px;
  margin-bottom: 10px;
  gap: 20px; /* Adds space between the buttons */
}

.actionButton {
  display: inline-block;
  text-decoration: none;
  padding: 8px 15px;
  font-size: 16px;
  color: rgb(0, 0, 0);
  background-color: #16db93;
  /* border: solid rgb(255, 255, 255) 1px; */
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 170px;
  text-align: center;
  font-weight: bold;
}

/* Adjust button size for mobile */
@media (max-width: 768px) {
  .actionButton {
    padding: 10px;
    font-size: 14px;
    width: 200px;
  }
}

.actionButton:hover {
  background-color: #a0eacf;
}

.actionButton:focus {
  outline: none;
}

.gap-section {
  margin: 10px;
  background-color: white;
}

.overview-section-main {
  background-image: linear-gradient(to right, #16db93, #542e8b);
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 768px) {
  .overview-section-main {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.overview-section {
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.overview-section h2 {
  font-size: 35px;
  color: white;
}

@media (max-width: 768px) {
  .overview-section h2 {
    font-size: 1.8em;
    max-width: 90%;
    text-align: center;
  }
}

.featured-items {
  display: flex;
  justify-content: center; /* Centers the buttons horizontally */
  gap: 60px; /* Adds space between the buttons */
}

/* Adjust layout for featured items on mobile */
@media (max-width: 768px) {
  .featured-items {
    flex-direction: column;
    gap: 30px;
  }
}

.featured-item {
  text-align: center;
  padding: 15px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 33%;
}

/* Ensure featured items take full width on mobile */
@media (max-width: 768px) {
  .featured-item {
    width: 80%;
    margin: auto;
  }
}

.featured-item h3 {
  font-size: 25px;
  text-align: center;
  margin: 0px;
  margin-bottom: 10px;
  color: white;
}

.featured-item p {
  margin: 0px;
  color: white;
}

.blog-preview-main {
  background-image: linear-gradient(to right, #16db93, #542e8b);
  background-size: cover;
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (max-width: 768px) {
  .blog-preview-main {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.blog-preview {
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.blog-preview h2 {
  text-align: center;
  font-size: 35px;
  color: rgb(255, 255, 255);
}

@media (max-width: 768px) {
  .blog-preview h2 {
    font-size: 1.8em;
    max-width: 90%;
  }
}

.blog-list-preview a {
  text-align: center;
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.blog-list-preview {
  border-radius: 7px;
  background-color: rgb(255, 255, 255, 0.1);
  border: none;
  padding: 10px 10px 0px 10px;
}

@media (max-width: 768px) {
  .blog-list-preview {
    max-width: 80%;
  }
}

@media (max-width: 768px) {
  .blog-post-content p {
    display: none;
  }
}

@media (max-width: 768px) {
  .blog-post-content h3 {
    font-weight: normal;
  }
}

.blog-post-preview {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  background-color: rgba(26, 26, 26, 0.15);
  border-radius: 5px;
  transition: background-color 0.3s ease;

  /* border: 1px solid #e8e8e8; */
}

@media (max-width: 768px) {
  .blog-post-preview {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.blog-post-preview:hover {
  background-color: rgba(125, 125, 125, 0.3);
}

.blog-list-preview p {
  color: rgb(255, 255, 255);
}

.what-you-will-learn-section {
  background-color: rgb(0, 0, 0);
  max-width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.what-you-will-learn {
  max-width: 1200px;
  align-items: center; /* Vertically center the items */
}

.what-you-will-learn h2 {
  text-align: center;
  font-size: 35px;
  color: rgb(255, 255, 255);
}

.learnings {
  gap: 10px;
}

.single-learning {
  display: flex;
  justify-content: center;
  gap: 10px;
  color: white;
  align-items: center; /* Vertically center the items */
}

@media (max-width: 768px) {
  .single-learning {
    margin: auto;
    text-align: left;
    justify-content: left;
    max-width: 60%;
    font-size: 1em;
  }
}

.checkbox {
  font-weight: bolder;
  font-size: 15px;
  color: #16db93;
}

.youtube-preview {
  padding: 40px 20px;
  text-align: center;
}

.youtube-video iframe {
  width: 100%;
  max-width: 800px;
  height: 450px;
  border-radius: 8px;
}

/* Template Section */
.template {
  background-color: #000000;
  padding: 40px 20px;
  text-align: left;
}

.template-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  margin-bottom: 80px;
}

.template-title {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 25px;
  text-align: center;
}

.template-description {
  font-size: 1rem;
  color: #ffffff;
  margin-bottom: 20px;
  text-align: left;
  justify-content: left;
}

/* Info Box */
.template-info-box {
  background-color: #1a1a1a;
  border-left: 5px solid #16db93;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #ffffff;
}

/* Modern Features Section */
.template-features {
  /* display: flex; */
  justify-content: space-between;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  gap: 40px;
  li::before {
    content: "▶";
    background: #f39c12;
    color: white;
    border-radius: 1ch;
    padding-inline: 1ch;
    margin-inline-end: 1ch;
  }
}

.features-left,
.features-right {
  flex: 1;
}

.audience-list li,
.feature-list li,
.technique-list li {
  font-size: 1.1rem;
  color: #ffffff;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.audience-list li i,
.feature-list li i,
.technique-list li i {
  color: #f39c12;
  margin-right: 10px;
  font-size: 1.2rem;
}

/* Features Grid */
.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.feature-box-traditional {
  background-color: #272727;
  border: #000000 solid 3px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  text-align: left;
  color: #ffffff;
  transition: transform 0.3s ease;
  li::before {
    content: "✖";
    background: #858585;
    color: rgb(255, 255, 255);
    border-radius: 1ch;
    padding-inline: 1ch;
    margin-inline-end: 1ch;
  }
}

.feature-box-new {
  background-color: #2f6d3b;
  border: #16db93 solid 3px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  text-align: left;
  color: #ffffff;
  transition: transform 0.3s ease;
  li::before {
    content: "✓";
    background: #16db93;
    color: rgb(0, 0, 0);
    border-radius: 1ch;
    padding-inline: 1ch;
    margin-inline-end: 1ch;
  }
}
.new-models li,
.traditional-models li {
  font-size: 1.1rem;
  color: #ffffff;
  margin-bottom: 15px;
  display: flex;
}

.template-highlight {
  margin: auto;
  max-width: 1100px;
  padding: 40px;
  margin-bottom: 80px;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 30px;
  padding: 0 10px;
}

.feature-item {
  border-bottom: 2px solid #ffffff;
  padding: 20px 0;
  transition: transform 0.2s ease;
}

.feature-item h3 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 5px;
}

.feature-item p {
  color: #989898;
  font-size: 0.95rem;
  line-height: 1.6;
  margin: 0;
}

/* Call to Action */
.actionButton-container {
  text-align: center;
  margin-top: 30px;
}

/* .actionButton {
  background-color: #f39c12;
  color: #ffffff;
  padding: 15px 30px;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
} */

/* .actionButton:hover {
  background-color: #e67e22;
} */

/* Youtube Implementation  */
.video-section {
  padding: 2rem 1rem;
  text-align: center;
  background-color: #000000;
}

.video-title {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 600px; /* Smaller container width */
  aspect-ratio: 16 / 9; /* Maintains the aspect ratio */
  margin: 0 auto;
  overflow: hidden;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.template-container {
  padding: 2rem;
}

.template-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1rem;
}
