body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osxfont-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  max-width: 1200px;
  text-align: center;
  margin: auto;
  padding: 10px;
}

header h1 {
  font-size: 36px;
  color: #333;
  margin-bottom: 5px;
  margin-top: 0px;
  text-align: left;
}

@media (max-width: 768px) {
  header h1 {
    font-size: 1.5em;
  }
}

header p {
  font-size: 14px;
  color: #666;
  text-align: justify;
  margin-top: 0px;
  margin-bottom: 0px;
}

.introduction {
  background-color: rgb(219, 248, 225);
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  /* border: solid 2px rgb(0, 0, 0); */
}

/* Blog Main Conent */

.blog-container {
  display: flex;
  max-width: 1200px;
  margin: auto;
  border-radius: 5px;
  height: 75vh; /* Set height to 60% of the viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.blog-list {
  flex: 1;
  overflow-y: auto; /* Enable vertical scrolling for blog list */
  padding: 10px;
  border: solid #e5e7eb;
  background-color: rgb(237, 237, 237);
  box-sizing: border-box;
}

.blog-details {
  flex: 2;
  overflow-y: auto; /* Enable vertical scrolling for blog details */
  border: solid #e5e7eb;
  padding: 10px;
}

@media (max-width: 768px) {
  .blog-details {
    display: none;
  }
}

.title-section-with-fullscreen-icon {
  display: flex;
  flex: 0.5;
  justify-content: space-between;
}

.blog-details-title-section {
  max-width: 90%;
}

/* Custom scrollbar */
.blog-container::-webkit-scrollbar,
.blog-details::-webkit-scrollbar,
.blog-list::-webkit-scrollbar {
  width: 8px; /* Adjust the width as needed */
}

.blog-container::-webkit-scrollbar-thumb,
.blog-details::-webkit-scrollbar-thumb,
.blog-list::-webkit-scrollbar-thumb {
  background-color: #2f8332; /* Greenish scrollbar thumb color */
  border-radius: 4px; /* Rounded corners */
}

.blog-container::-webkit-scrollbar-track,
.blog-details::-webkit-scrollbar-track,
.blog-list::-webkit-scrollbar-track {
  background-color: #f2f2f2; /* Light grey scrollbar track */
}

.blog-post {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #ffffff;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  border-radius: 5px;
  /* border: 2px solid #e8e8e8; */
}

.blog-post:hover {
  background-color: #f9f9f9;
}

.blog-post img {
  width: 100px;
  height: auto;
  margin-right: 20px;
}

.blog-post div {
  flex: 1;
}

.blog-post h2 {
  font-size: 18px;
  margin-bottom: 0px;
  margin-top: 0px;
  color: rgb(0, 0, 0);
}

.blog-post h4 {
  font-size: 14px;
  color: rgb(156 163 175);
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 5px;
}

.blog-post p {
  font-size: 16px;
  color: #666;
  margin: 0;
}

.blog-details div {
  border-radius: 5px;
}

.blog-details h2 {
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.blog-details h4 {
  font-size: 14px;
  color: rgb(156 163 175);
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 5px;
}

.blog-details img {
  max-width: 100%;
  height: auto;
  max-height: 200px;
  margin-bottom: 20px;
}

.blog-details p {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
}

.selected h2 {
  color: #2f8332; /* Change the color as desired */
}

/* Navbar */
nav {
  background-color: #ffffff; /* Set background color for navbar */
  padding: 10px 20px; /* Add padding to navbar */
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: auto;
  /* border-bottom: solid 2px #000000; */
}

.logo img {
  width: 150px; /* Adjust width of logo */
  height: auto; /* Ensure logo maintains aspect ratio */
}

@media (max-width: 768px) {
  .logo img {
    width: 120px;
    height: auto;
  }
}

.menu {
  list-style-type: none; /* Remove bullet points from menu */
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  text-align: right;
  gap: 20px;
}

.menu a {
  color: hsl(0, 0%, 0%); /* Set color for menu items */
  text-decoration: none; /* Remove underline from menu items */
  font-size: 16px; /* Set font size for menu items */
  transition: color 0.3s ease; /* Add transition effect for color change */
}

.menu a:hover {
  color: #559de4; /* Change color on hover */
}

.menu a.active {
  text-decoration: underline;
}

.conversionButton a {
  padding: 12px 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #000000; /* Green */
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s, background-color 0.5s ease-in-out; /* Added transition for background-color */
  cursor: pointer;
}

.conversionButton a:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 10px rgb(255, 255, 255);
  background-color: #16db93; /* Green with opacity for fade effect */
  color: white;
}

.conversionButton a:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
  color: white;
}

@media (max-width: 768px) {
  .conversionButton a {
    padding: 0px;
    color: #16db93;
    background-color: white;
    text-transform: capitalize;
    box-shadow: none;
  }
  .menu {
    gap: 8px;
  }
}

/* Footer */
footer {
  padding: 10px 20px; /* Add padding to navbar */
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: auto;
}

/* footer p {
  font-size: 14px;
} */

.footer {
  list-style-type: none; /* Remove bullet points from menu */
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.footer li {
  margin-right: 20px; /* Add spacing between menu items */
}

.footer li:last-child {
  margin-right: 0; /* Remove margin from last menu item */
}

.footer a {
  color: rgb(184, 184, 184); /* Set color for menu items */
  text-decoration: none; /* Remove underline from menu items */
  font-size: 16px; /* Set font size for menu items */
  transition: color 0.3s ease; /* Add transition effect for color change */
}

.footer a:hover {
  color: #559de4; /* Change color on hover */
}

.copyrightText {
  color: rgb(184, 184, 184);
}

/* Special Formatting  */
#underline {
  text-decoration-color: #16db93;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
  text-decoration-line: underline;
  text-decoration-style: double;
}

.code-highlight {
  background-color: #c5e8c6d9;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 4px;
}

.blog-details blockquote p {
  color: #000000;
  font-weight: bold;
}

.special-underline {
  text-decoration-color: #16db93;
  text-decoration-thickness: 0.2em; /* Example thickness relative to font size */
  background-color: #c5e8c6d9; /* Example background color */
}

/* Search Field Styles */
.search-field {
  margin-bottom: 15px;
  width: 100%; /* Set width to 100% to expand within its container */
}

.search-field input {
  width: calc(
    100% - 24px
  ); /* Calculate input width to match blog-list content width */
  /* Adjust padding to match blog-list item padding */
  padding: 12px;
  /* Match border and border-radius styles */
  border: 2px solid #e5e7eb;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  transition: border-color 0.3s ease;
}

.search-field input:focus {
  outline: none;
  border-color: #16db93;
}

.fullscreen-button img {
  width: 20px;
  height: 20px;
  margin: 0;
  background-color: none;
}

.fullscreen-button button {
  background-color: white;
  border: solid #16db93 1px;
  border-radius: 3px;
  align-items: center;
  padding: 5px;
  margin: 0;
  height: 32px;
  width: 32px;
}

.fullscreen-button button:hover {
  background-color: rgb(175, 228, 166);
  border: solid #16db93 1px;
  transition-duration: 500ms;
  cursor: pointer;
}
/* CSS for the tooltip text */
.tooltip-container {
  position: relative; /* Set position relative for the container */
  display: inline-block; /* Ensure inline block display for proper alignment */
}

.tooltip-image-text {
  visibility: hidden; /* Initially hide the tooltip text */
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text relative to its container */
  position: absolute;
  top: 50%; /* Center vertically within the container */
  right: 140%; /* Position to the left of the container */
  transform: translateY(-50%); /* Center vertically */
  z-index: 1; /* Ensure the tooltip is above other content */
}

.tooltip-container:hover .tooltip-image-text {
  visibility: visible; /* Show the tooltip text on hover of the container */
}
