.blog-details-fullscreen {
  max-width: 1200px;
  margin: auto;
}

@media (max-width: 768px) {
  .blog-details-fullscreen {
    padding: 10px;
  }
}

.back-button-container {
  margin: 15px auto 15px auto;
}

.back-button {
  display: inline-block;
  text-decoration: none;
  padding: 8px 15px;
  font-size: 16px;
  color: #525252;
  border: solid gray 1px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #d1d1d1;
}

.back-button:focus {
  outline: none;
}

@media (max-width: 768px) {
  .back-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}

.code-highlight {
  color: #2f8332;
  font-weight: bold;
}

/* recommended post section */
.recommended-section {
  border-top: solid gray 2px;
  color: #737673;
  margin-top: 50px;
}
.recommended-posts-container {
  color: #737673;
  background-color: #ffffff; /* Light background color */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  display: flex;
  flex-wrap: wrap; /* Allow posts to wrap to next line if needed */
  justify-content: space-between; /* Distribute items evenly */
  gap: 20px; /* Spacing between posts */
}

.recommended-post {
  flex: 0 0 calc(30% - 20px); /* Each post occupies 30% width with spacing */
  background-color: #f8f9fa; /* White background for each post */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
}

.recommended-post h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #737673;
  text-decoration: none;
}

.recommended-post p {
  font-size: 14px;
  color: #666666;
}

@media (max-width: 768px) {
  .recommended-post {
    flex: 100%; /* Two posts per row on smaller screens */
  }
}
