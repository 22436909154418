.introduction {
  padding: 5px 10px;
  text-align: left;
  text-align: center;
  align-content: center;
}

.introduction p {
  font-size: 16px;
  color: #666;
  margin-bottom: 5px;
}

.split-section {
  display: flex;
  max-width: 1200px;
  margin: auto;
  overflow-y: auto; /* Enable vertical scrolling */
}

.split-section-1 {
  display: ruby;
  align-items: center;
  text-align: center;
  border: solid black 1px;
  width: auto;
  padding: 10px;
}

.split-section-2 {
  margin-left: 10px;
}

.container {
  text-align: left;
}

@media (max-width: 768px) {
  .split-section {
    display: grid;
    padding: 10px;
  }
  .split-section-1 {
    border: none;
    text-align: left;
    padding: 0px;
  }

  .split-section-2 {
    margin-left: 0;
  }

  .container p {
    display: none;
  }
}

.button {
  border: none;
  outline: none;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #555;
}
