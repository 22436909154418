/* LandingPage.css */
.landing-page-container {
  max-width: 1200px;
  margin: auto;
  height: 500px;
}

.header {
  text-align: center;
  margin: auto;
  padding: 10px;
}

.header h1 {
  font-size: 36px;
  color: #333;
  margin-bottom: 5px;
  margin-top: 0px;
  text-align: left;
}

.header h2 {
  text-align: center;
  align-items: center;
}

.header p {
  font-size: 14px;
  color: #666;
  text-align: justify;
  margin-top: 0px;
  margin-bottom: 0px;
}
